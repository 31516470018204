@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertCenter {
  position:absolute;
  top:0px;
  transform: translate(0%, 25%);
}

@mixin pseudoAbsolute {
    content:'';
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
}

@mixin pseudoBlank {
  content:'';
  position:absolute;
}

@mixin plainList {
  list-style-type:none;
  padding-left:0px;
}

@mixin bgCenter {
  background-size:cover!important;
  background-repeat:no-repeat!important;
  background-position:center center!important;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}