@import url('./fonts/styles.css');



@font-face {
    font-family: 'Sofia Pro';
    src: url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.eot');
    src: url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.eot?#iefix') format('embedded-opentype'),
        url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.woff2') format('woff2'),
        url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.woff') format('woff'),
        url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.ttf') format('truetype'),
        url('/wp-content/themes/bootstrap-four/css/fonts/SofiaProRegular.svg#SofiaProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}


// fonts
$bodyFont: 'Sofia Pro', sans-serif;
$headerFont: 'Sofia Pro', sans-serif;

// colors

$white:#fff;
$black:#000;
$pink:#F274AC;
$blue:#7ABFDD;
$orange:#FBB03B;
$lightOrange:#FEB100;
$green:#ABC561;
$lightGrey:#F8F8F8;
$grey:#C5C5C5;