@import 'variables.scss';
@import 'mixins.scss';
@import 'woo.scss';
/*
Theme Name: Core Theme
Author: Frogspark
Version: 3000
*/


/* * * * * * * * * * * * * * *
 * WordPress Content Classes
 * * * * * * * * * * * * * * */

 body {
     -webkit-font-smoothing: antialiased;
     color:black;
 }

 *:focus {
     outline:none;
 }

.wow {
    visibility:hidden;
}

p {
  padding-top:0px;
  font-family:$bodyFont;
}

.content-section {
    padding:80px 0px;
	p {
        line-height:1.8em!important;
        margin-bottom:20px;
    }
    .header {
        margin-bottom:30px;
        font-size:45px;
    }
    .img-responsive {
        margin-bottom:30px;
    }
}

img {
  max-width: 100%;
}

a, button, span {
  text-decoration: none !important;
  outline: none !important;
  font-family:$headerFont;
}

h1, h2, h3, h4, h5, label {
  margin: 0px;
  padding: 0px;
  line-height: 1.8em;
  font-weight:bold;
  font-family:$headerFont;
}

.title-brush {
    position:relative;
    z-index:0;
}

.brush {
    position:relative;
    z-index:-1;
    margin-bottom:10px;
}

.brush.right {
    z-index:0;
}

.brush.right::after {
    content:'';
    width:125px;
    height:52px;
    left:calc(100% - 145px);
    top:1px;
}

.brush::after {
    content:'';
    position:absolute;
    width: 130%;
    height: 130%;
    background-image:url('/img/scribble.png');
    @include bgCenter;
    z-index:-1;
}

.brush::after {
    top: -4px;
    left: -10px;
}

.title.brush.right::after {
    top:0px;
    right:-10px;
}

a, button {
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}

a.btn:hover {
    opacity:0.8;
}

.mobile-nav {
    .container {
        position:relative;
    }
    .hamburger {
        cursor:pointer;
        position: absolute;
        top: 10px;
        right: 30px;
    }
}

.navbar {
    min-height: 1px;
    height:inherit!important;
	margin-bottom: 0px;
	padding:20px 0px;
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style:none;
            a {
                padding-top:10px!important;
                color:$white!important;
                font-size:20px;
            }
        }
    }
    .container {
        .navbar-brand {
            margin-top:0px;
            padding: 0px;
            height:auto;
            img {
                height:50px;
                margin-top:10px;
                display: block;
                margin-left:15px!important;
            }
        }
    }
}

.navbar.fixed-navbar {
    padding:5px 0px!important;
}

#navbar {
    .navbar-nav {
        margin-top:15px;
        li {
            display:table!important;
            height:37px;
            vertical-align:middle;
            margin:0px 10px;
        }
        li:last-child {
            margin-right: 0px !important;
        }
    }
}

.navbar-inverse {
    background-color:transparent;
	border: none;
	position: fixed;
    transition:.5s ease-in-out;
    width:100%;
    z-index:1050;
    &:after, &:before {
        content:'';
        position:absolute;
        height:80px;
        width:100vw;
        top:-100px;
        left:0px;
        opacity:0;
        @include bgCenter;
        @include transition(0.7s);
    }
    &:after {
        background:url('/img/african-texture.jpg');
        z-index:-2;
    }
    &:before {
        background:linear-gradient(to left, rgba(122, 191, 221,1),rgba(122, 191, 221,0.9), rgba(72, 198, 96,0.9), rgba(255, 177, 40,0.9));
        z-index:-1;
        box-shadow:0px 5px 10px rgba(0, 0, 0, 0.1);
    }
}

.navbar-inverse.fixed-navbar {
    margin-top:0px;
    &:after, &:before {
        top:0px;
        opacity:1;
    }
}

.topgap {
  height: 0px;
  width: 100%;
  display: block;
}

.topgap.fixed-nav {
    height:80px;
}

.mobile-nav-wrap {
  visibility:hidden;
  z-index:1080;
  position:fixed;
  height:100%;
  width:100%;
  right:-100%;
  opacity:1;
  .hamburger {
    position: absolute;
    top: -60px;
    right: 30px;
  }
}

.mobile-nav {
    display:none;
}


.mobile-nav-wrap {
    @include transition(0.5s);
    @include bgCenter;
    background:linear-gradient(180deg, rgba(122, 191, 221,0.97),rgba(122, 191, 221,0.95), rgba(72, 198, 96,0.95)), url('/img/african-texture.jpg');
    .mobile-navlinks {
        @include transition(0.5s);
        .container {
            position:relative;
        }
        @include plainList;
        margin-top:100px;
        text-align:center;
        @include transition(0.2s);
        li {
            a {
                font-size:20px;
                color:$white;
                line-height:35px;
                opacity:0;
                @include transition(0.5s);
            }
            a:hover {
                opacity:0.8;
            }
        }
        .contact {
            a {
                color:$orange;
                font-size:22px;
                padding:0px 15px;
                opacity:0;
                @include transition(0.5s);
            }
        }
        .sm {
            li {
                display:inline-block;
                a {
                    color:$white;
                    padding:0px 15px;
                    opacity:0;
                    @include transition(0.5s);
                    i {
                        font-size:35px;
                    }
                }
            }
        }
    }
}

.mobile-nav-wrap.show-menu {
    visibility:visible;
    z-index:1050;
    opacity:1;
    height:100%;
    right:0px;
    @include transition(0.5s);
    .mobile-navlinks {
        @include plainList;
        margin-top:100px;
        text-align:center;
        @include transition(0.5s);
        li {
            a {
                opacity:1;
            }
        }
        .contact {
            a {
                opacity:1;
            }
        }
        .sm {
            li {
                a {
                    opacity:1;
                }
            }
        }
    }
}


.validation_error, .validation_message {
    font-size:16px;
    font-family:$bodyFont;
    margin-top:10px;
    font-weight:bold;
    color:$black;
}

.woocommerce-product-gallery__wrapper {
  width: 70%;
}

body img.gform_ajax_spinner {
    display: none!important;
}

.btn, form input[type=submit] {
    border-radius:5px;
    padding:10px 25px;
    font-family:$headerFont;
    font-size:20px;
    border:none;
}

.btn-circle {
    display: inline-block;
    margin: auto;
    border: 2px solid #fff;
    width: 100px;
    height: 100px;
    font-size: 40px;
    color: #fff;
    border-radius: 50%;
    margin-top: 15px;
    position: relative;
    text-align:center;
    cursor:pointer;
    i {
        color:$white;
        position:relative;
        line-height:80px;
        font-size:30px;
        left:4px;
        @include transition(0.3s);
    }
}

.btn-sml {
    border-radius:5px;
    padding:7px 24px;
    font-size:15px;
}

.btn-sml.orange {
    color:$white;
    background-color:$orange;
}

.btn-circle:hover {
    i {
        color:$black;
    }
}

.btn-circle::before {
    content: '';
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 50%;
    transform:scale(0);
    @include transition(0.4s);
}

.term-donate .inner.single-product {
  display: none !important;
}

.term-donate .cta-section.archive .info-box {
  float: none !important;
  display: inline-block !important;
}

.postid-89 .entry-summary .price {
  display: none;
}

.postid-89 .woocommerce-variation-price .price {
  display: block !important;
}

.term-donate .info-box-row {
  text-align: center !important;
}

.btn-circle:hover::before {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    transform: scale(1);
}

.btn-line {
    font-size:12px;
    display:inline;
    border-bottom:2px solid;
    cursor:pointer;
    @include transition(.3s);
}

.btn-line:hover {
    border-color:$white!important;
}

.btn.btn-pink, form input[type=submit] {
    color:$white;
    background-color:$pink;
    border:2px solid $white;
    @include transition(0.3s);
}

.btn.btn-clear {
    color:$white;
    background-color:transparent;
    border:2px solid $white;
    @include transition(0.3s);
}

.btn.btn-clear.black {
    color:$black;
    border:2px solid $black;
}

.btn.btn-clear.black:hover {
    color:$white;
    background-color:$black;
}

.btn.btn-primary:hover, form input[type=submit]:hover {
    opacity:0.85;
}

.btn.btn-secondary {
    background-color:$white;
    @include transition(0.3s);
}

.btn.btn-secondary:hover {
    opacity:0.85;
}

footer {
    background-color:#F8F8F8;
    .footer-col {
        .footer-title {
            font-size:20px;
            color:$black;
        }
    }  
    .footer-list {
        @include plainList;
        li {
            a {
                color:$black;
                font-family:$bodyFont;
                font-size:14px;
                line-height:30px;
            }
        }
    }
    .row.menu {
        padding-top:60px;
        padding-bottom:40px;
        border-bottom:1px solid #CBCBCB;
    }
    .row.copy {
        margin-top:40px;
        margin-bottom:40px;
        text-align:center;
        .sm-list {
            @include plainList;
            padding:20px 0px;
            li {
                display:inline-block;
                padding:0px 10px;
                a {
                    color:#98a2ab;
                }
            }
        }
        .copyright {
            margin-top:30px;
            font-family:$bodyFont;
            color:#98a2ab;
            a {
                color:#98a2ab;
            }
        }
    }
}

.hero {
    padding:100px 0px 190px 0px;
    .container {
        position:relative;
    }
    @include bgCenter;
    .hero-h1 {
        font-size:50px;
    }
    .hero-h2 {
        font-size:30px;
        margin-bottom:30px;
    }
    .hero-content {
        margin-top:50px;
    }
    .btn-clear {
        margin-left:15px;
    }
}

.hero.header {
    padding:100px 0px 100px 0px;
    b.accent {
        color:$pink;
    }
    .accent.green {
        color:$green;
    }
    .offset-header {
        font-size:35px;
    }
    .staff-member {
        color:$pink;
        font-size:20px;
    }
    .flag {
        height:42px;
        width:42px;
        position:relative;
        top:-5px;
        box-shadow:0px 5px 10px rgba(0,0,0,0.5);
    }
    .offset-text.with-avatar {
        .avatar {
            width:85px;
            height:85px;
            display:inline-block;
            position:relative;
            top:-30px;
        }
        p {
            display:inline-block;
            width:75%;
            margin-left:10px;
        }
    }
    .offset-text {
        line-height:2em;
    }
}

.dark-section {
    h1,h2,h3,h4,h5,p {
        color:$white;
    }
}

.cta-section {
    margin-top:-140px;
    .row.title {
        padding-left:15px;
    }
}

.cta-section.no-offset {
    margin-top:0px!important;
    .row:not(:first-child){
        margin-top:40px;
    }
}

.cta-section.no-offset.sponsor {
    padding-top:60px;
    background-color:$white;
}

.cta-section.no-offset.sponsor.children {
    padding-top:60px;
    background-color:#F9F9F9;
}

.inner {
    .btn-circle {
        i {
            line-height:77px;
            margin-left:1px;
        }
    }
}

.info-box-row {
    a {
        color:$black;
    }
}

.info-box {
    text-align:center;
        .inner {
        margin:0px 15px;
        border:2px solid $white;
        border-radius:5px;
        display:inline-block;
        box-shadow:2px 5px 15px rgba(0, 0, 0, 0.15);
        position:relative;
        .tab-title {
            padding:10px 25px;
            color:$white;
            opacity:0.5;
            font-size:12px;
        }
        .avatar {
            margin-left:25px;
            height:98px;
            width:98px;
        }
        .tab-img {
            width:100%;
            height:120px;
            display:inline-block;
            @include bgCenter;
        }
        .info {
            padding:10px 25px 25px 25px;
            position:relative;
            .location {
                font-size:24px;
                color:$white;
            }
            .btn-line {
                color:$black;
                border-color:$black;
            }
            p {
                color:$white;
            }
        }
        .logo-icon {
            position:absolute;
            right:25px;
            bottom:25px;
            width:39px;
            height:39px;
            @include transition(.3s);
        }
    }

    .inner.single-product {
        @include bgCenter;
        margin:0 auto;
        width:170px;
        height:200px;
        display:inline-block;
        border:none;
    }
    .price {
        color:$pink;
        font-size:14px;
        margin-bottom:10px;
    }
    .line-btn {
        border-bottom:1px solid $black;
        font-size:14px;
    }
    cursor:pointer;
    @include transition(0.4s);
}

.info-box:hover {
    transform:scale(1.04);
}

.info-box.pink {
    .inner {
        background-color:$pink;
    }
}

.info-box.green {
    .inner {
        background-color:$green;
    }
}

.info-box.blue {
    .inner {
        background-color:$blue;
    }
}

.info-box.orange {
    .inner {
        background-color:$orange;
    }
}

//sponsor kids

.info-box.sponsor {
    .inner {
        text-align:left!important;            
        margin:0px 15px;
        border:2px solid $white;
        border-radius:5px;
        display:inline-block;
        box-shadow:2px 5px 15px rgba(0, 0, 0, 0.15);
        position:relative;
        .tab-title {
            padding:10px 25px;
            color:$white;
            opacity:0.5;
            font-size:12px;
        }
        .avatar {
            margin-left:25px;
            height:98px;
            width:98px;
        }
        .tab-img {
            width:100%;
            height:120px;
            display:inline-block;
            @include bgCenter;
        }
        .info {
            padding:10px 25px 25px 25px;
            position:relative;
            .location {
                font-size:25px;
                color:$white;
            }
            .btn-line {
                color:$black;
                border-color:$black;
            }
            p {
                color:$white;
            }
        }
        .logo-icon {
            position:absolute;
            right:25px;
            bottom:25px;
            width:39px;
            height:39px;
            @include transition(.3s);
        }
    }
    cursor:pointer;
    @include transition(0.4s);
}

.info-box.sponsor:hover {
    transform:scale(1.04);
}

.info-box.sponsor.pink {
    .inner {
        background-color:$pink;
    }
}

.info-box.sponsor.green {
    .inner {
        background-color:$green;
    }
}

.info-box.sponsor.blue {
    .inner {
        background-color:$blue;
    }
}

.info-box.sponsor.orange {
    .inner {
        background-color:$orange;
    }
}


.section-mission {
    position:relative;
    &:after {
        content:'';
        position:absolute;
        left:calc(50% - 22px);
        bottom:-24px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 24px 22px 0 22px;
        border-color:$orange transparent transparent transparent;
    }
    .next {
        font-size:18px;
        font-weight:bold;
    }
    .mission-code {
        display:inline-block;
        padding:20px 40px;
        background-color:$white;
        border:2px solid $pink;
        border-radius:7.5px;
        font-size:25px;
        text-transform:uppercase;
    }
    .mission-box {
        overflow:hidden;
        border-radius:10px;
        padding:0px;
        margin-top:30px;
        margin-bottom:50px;
    }
    .video-col, .info-col {
        @include bgCenter;
        // padding:0px;
    }
    .video-col {
        .btn-circle {
            @include centerer;
        }
    }
    .info-col {
        background-color:$white;
        position:relative;
        .sml-title {
            font-size:15px;
        }
        p {
            font-size:30px;
            line-height:55px;
            font-weight:600;
        }
        a.btn {
            font-size:20px;
            margin-top:20px;
        }
        .inner {
            margin:30px 20px;
            padding:40px;
            border:1px solid $orange;
            border-bottom-right-radius:10px;
            border-top-right-radius:10px;
        }
    }
    .link {
        font-weight:700;
        font-size:30px;
        a {
            color:$black;
            padding-bottom:2px;
        }
        a:hover {
            border-bottom:3px solid $black;
        }
    }
}

.mission-list {
    @include bgCenter;
}

.mission-thumb {
    .inner {
        padding:100px 25px 40px 25px!important;
        display:block;
        @include bgCenter;
        border-radius:10px;
        overflow:hidden;
        position:relative;
        z-index:0;
        box-shadow:2px 5px 15px rgba(0, 0, 0, 0.15);
    }
    .col-md-12 {
        margin-top:20px;
        padding:0px;
    }
}

.mission-thumb.green {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(172,198,96,0.65);
            z-index:-1;
            @include transition(.4s);
        }
    }
    .btn:hover {
        background-color:$white;
        color:$green;
    }
}

.mission-thumb.green {
    .inner:hover {
        &:after {
            background-color:rgba(172,198,96,0.8);
        }
    }
}

.mission-thumb.orange {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(255,177,40,0.65);
            z-index:-1;
        }
    }
    .btn:hover {
        background-color:$white;
        color:$orange;
    }
}

.mission-thumb.orange:hover {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(255,177,40,0.8);
            z-index:-1;
            @include transition(.4s);
        }
    }
}

.mission-thumb.blue {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(122,191,221,0.65);
            z-index:-1;
        }
    }
    .btn:hover {
        background-color:$white;
        color:$blue;
    }
}

.mission-thumb.blue:hover {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(122,191,221,0.8);
            z-index:-1;
            @include transition(.4s);
        }
    }
}

.mission-thumb.black {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(0,0,0,0.33);
            z-index:-1;
        }
    }
    .btn:hover {
        background-color:$white;
        color:$black;
    }
}

.mission-thumb.black:hover {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(0,0,0,0.5);
            z-index:-1;
            @include transition(.4s);
        }
    }
}

.mission-thumb.pink {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(246,112,172,0.65);
            z-index:-1;
        }
    }
    .btn:hover {
        background-color:$white;
        color:$pink;
    }
}

.mission-thumb.pink:hover {
    .inner {
        &:after {
            @include pseudoAbsolute;
            background-color:rgba(246,112,172,0.8);
            z-index:-1;
            @include transition(.4s);
        }
    }
}

.child-sponsor {
    margin-top:20px;
    .inner {
        padding-top:60px!important;
        .mission-title {
            margin-top:30px;
        }
    }
}

.mission-title {
    color:$white!important;
    margin-bottom:10px;
}

.avatar {
    display:inline-block;
    width:135px;
    height:135px;
    border-radius:50%;
    @include bgCenter;
}

.sponsor-carousel {
    position:relative;
    z-index:0;
    padding-top:20px;
    padding-bottom:20px;
    text-align:center;
    color:$white!important;
    a {
        color:$white!important;
    }
    &:after {
        content:'';
        position:absolute;
        left:-25px;
        top:0px;
        width:calc(100% + 50px);
        height:100%;
        background-color:rgba(113,113,113,0.35);
        z-index:-1;
    }
    .slick-dots {
        display:none!important;
    }
    .name {
        font-size:25px;
    }
    .country {
        font-size:15px;
    }
    .slick-prev, .slick-next {
        z-index:50;
        border:none;
        background-color:transparent!important;
        color:transparent!important;
        width:0px;
        height:0px;
        opacity:1;
        &:after {
            content:"\e902";
            font-family:'icomoon';
            font-size:30px;
            color:$white;
            position:absolute;
            top:0px;
            left:0px;
        }
    }
    .slick-prev {
        position:absolute;
        top:calc(50% - 20px);
        left:10px;
        &:after {
            transform:rotate(180deg);
        }
    }
    .slick-next {
        position:absolute;
        top:calc(50% - 20px);
        right:10px;
    }
}

.fundraiser-cta {
    background-size:cover!important;
    background-position:center bottom!important;
    padding:40px 0px;
    color:$white!important;
    .fundraiser-title {
        display:inline;
        font-size:40px;
        font-weight:700px;
    }
    a.btn {
        float:right;
        margin-top:11px;
    }
    .btn:hover {
        color:$black!important;
        background-color:$white!important;
    }
}

.event-section , .double-img-section {
    .title {
        font-size:40px;
        margin-bottom:10px;
    }
    .title.sml {
        font-size:25px;
        margin-bottom:20px;
    }
    p {
        line-height:30px;
    }
    .speaker {
        display:inline-block;
        margin:10px 20px;
        .avatar {
            width:100px;
            height:100px;
        }
    }
    .btn {
        margin-top:30px;
    }
    .btn:hover {
        color:$black;
        background-color:$white;
    }
}

section.about-section, .double-img-section {
    @include bgCenter;
    .inner {
        position:relative;
        height:500px;
        width:100%;
        display:inline-block;
        box-shadow:0px 5px 10px rgba(0,0,0,0.1);
        @include bgCenter;
        .caption {
            position:absolute;
            bottom:0px;
            color:$white;
            padding:20px;
        }
        .btn-circle {
            @include centerer;
        }
    }
    .inner.medium {
        height:400px;
        margin-top:50px;
    }
    .inner.sml {
        height:300px;
    }
    .images-col {
        margin-top:40px;
    }
}

.founder {
    margin:100px 0px 0px 0px;
    padding:50px 0px;
    border-top:2px solid $black;
    border-bottom:2px solid $black;
    .founder-img {
        @extend .avatar;
    }
    .founder-name {
        margin-top:5px;
        margin-bottom:15px;
    }
}

.text-bgimg {
    @include bgCenter;
}

.double-img-section {
    .offset-up {
        margin-top:-100px;
    }
    .offset-down {
        margin-top:100px;
    }
}

table.missions {
    width:100%;
    tr {
        border-top:1px solid #979797;
        position:relative; 
        td {
            cursor:pointer;
        }  
    }
    tr:last-child {
        border-bottom:1px solid #979797;
    }
    td {
        font-size:25px;
        padding:10px 0px 12px 0px;
        position:relative;
        .icon {
            margin-right:15px;
        }
    }
    tr:hover + .desc td {
        height:100%;
    }
    td:hover .desc {
        opacity:1;
        margin-top:10px;
        padding:10px 20px;
        height:auto;
    }
    tr > td:nth-child(2){
        padding-left:10px;
        padding-right:10px;
    }
    .desc {
        background-color:$orange;
        font-size:15px;
        color:$white;
        clear:both;
        display:block;
        opacity:0;
        height:0px;
        padding:0px 20px;    
        margin:0px 10px;    
        span {
            height:0px;
            display:inline-block;
        }
        @include transition(0.2s);
    }
}

.store-item {
    width:100%;
    height:auto;
    input {
        background-color:#F0F0F0;
        color:#BBBBBB;
        border:none;
        padding:10px;
        font-family:$bodyFont;
        font-weight:bold;
    }
    .btn-pink {
        display:table;
        clear:both;
        margin-top:30px;
    }
    .shop-item {
        box-shadow:0px 5px 10px rgba(0,0,0,0.2);
    }
}

.product-carousel {
    .slick-list {
        box-shadow:0px 5px 10px rgba(0,0,0,0.2);
        height:500px;
    }
    .slide {
        .inner {
            @include bgCenter;
            height:500px;
            width:100%;
            display:inline-block;
            position:relative;
        }
        .avatar {
            position:absolute;
            top:25px;
            left:25px;
            box-shadow:0px 5px 10px rgba(0,0,0,0.2);
            height:90px;
            width:90px;
        }
    }
    .slick-next, .slick-prev {
        display:none!important;
    }
    .slick-dots {
        @include plainList;
        margin-top:20px;
        .slick-active {
            button {
                opacity:1;
            }
        }
        li {
            display:inline-block;
            button {
                background-color:$pink;
                border-radius:50%;
                height:15px;
                width:15px;
                border:none!important;
                color:transparent;
                opacity:0.5;
            }
        }
        li:not(:first-child){
            margin-left:10px;
        }
    }
}

.donate-buttons {
    @include plainList;
    li {
        display:inline-block;
        .btn-pink {
            background-color:$blue;
        }
    }
}

.contact-email {
    margin-top:10px;
    color:$black;
    display:inline-block;
}

a:hover {
    color:$black;
}

.contact-section {
    span.country {
        margin-top:10px;
        font-weight:bold;
    }
    .country > img {
        height:25px;
        width:25px;
        margin-right:10px;
    }
    ul {
        @include plainList;
        margin-top:15px;
        margin-bottom:40px;
        li {
            font-family:$bodyFont;
        }
    }
}

.contact-section {
    .title-brush {
        margin-bottom:30px;
    }
}

.contact-form {
    form {
        .gfield_label {
            position:relative;
            display:inline-block;
            margin-bottom:15px;
            font-size:18px;
        }
        .gfield_label.active {
            &:after {
                opacity:1;
                width:220px;
            }
        }
        input[type=text], input[type=password], textarea {
            width:100%;
            background-color:#F0F0F0;
            margin-bottom:30px;
            border:none;
            color: #000 !important;
            font-weight: 400 !important;
        }
        textarea {
            margin-bottom:0px;
            resize:none;
            padding-left: 10px !important;
            padding-top: 5px;
        }
        .gfield_required {
            margin-left:5px;
        }
    }
}

.woocommerce-breadcrumb {
    display:none!important;
    position:relative!important;
    left:-15px!important;
    padding:20px 20px!important;
    margin:40px 0px 40px 0px!important;
    display:inline-block;
    font-size:15px!important;
    color:$white!important;
    font-family:$headerFont!important;
    background-color:$blue!important;
    border-radius:7.5px!important;
    a {
        font-weight:bold!important;
        color:$white!important;
    }
}

.attachment-shop_single {
    box-shadow:5px 5px 10px rgba(0,0,0,0.2)!important;
}

.single_add_to_cart_button {
    color:$white!important;
    background-color:$pink!important;
    border:2px solid $white!important;
    font-size:20px!important;
    border-radius:7.5px!important;
    @include transition(0.3s);
    margin-top:20px!important;
    clear:both;
    display:block;
}

.product.type-product {
    .prod-desc {
        padding:20px 0px;
        line-height:28px;
    }
    .price {
        margin:20px 0px;
    }
    .product_meta a {
        color:$blue!important;
    }
    .product_title {
        font-size:25px!important;
        position:relative!important;
        display:inline-block!important;
        &:after {
            content:'';
            position:absolute;
            width: 100%;;
            height: 100%;
            transform:scale(0.7);
            background-image:url('/img/scribble.png');
            @include bgCenter;
            z-index:-1;
            top:0px;
            left:-25%;
        }
    }
    input[type=number]{
        background-color:#F0F0F0;
        border:none;
        padding:15px;
        margin-left:0px;
        font-family:$bodyFont;
    }
}

.single-product-section {
    padding-bottom:40px;
}

.single-product .cta-section {
    padding:80px 0px;
    .row.title {
        margin-bottom:40px;
    }
}

.cta-section.archive {
     .info-box {
         padding:0px;
     }
     .woocommerce-breadcrumb {
         margin-top:0px!important;
         left:0px!important;
     }
    .woocommerce-result-count {
        display:none;
    }
}

.pagination-col {
    margin-top:25px;
    .page-numbers {
        color:$white;
        background:url('/img/african-texture.jpg');
        @include bgCenter;
        width:50px;
        height:50px;
        line-height:45px;
        margin:10px;
        display:inline-block;
        text-align:center;
        border-radius:50%;
        border:2px solid $white;
        font-size:22px;
        box-shadow:5px 5px 5px rgba(0,0,0,0.1);
        position:relative;
        overflow:hidden;
        background-attachment:fixed;
        z-index:0;
        &:after {
            content:'';
            width:100%;
            height:100%;
            position:absolute;
            top:0px;
            left:0px;
            background-color:rgba(72, 198, 96,0.9);
            border-radius:50%;
            z-index:-1;
        }
    }
}

.donate-icon {
    width:25px;
    height:25px;
    position:relative;
    top:-5px;
    left:-5px;
}

li.donate {
    position:relative;
}

.donate-count {
    color:$blue;
    background-color:$white;
    width:20px;
    height:20px;
    border-radius:50%;
    position:absolute;
    top:-5px;
    right:-10px;
    text-align:center;
    font-size:12px;
    display:inline-block;
}

.prod-row {
    padding:0px 15px;
}

.single-product {
    .cta-section .row.title {
        padding-left:0px!important;
        p {
            line-height:2em;
            margin-top:5px;
        }
    }
}

.cart-empty, .return-to-shop {
    padding:25px;
}

.woocommerce-order-overview.order_details {
    li {
        display:block;
        clear:both;
        float:left;
        margin-top:20px;
    }
}

.checkout .create-account {
    display:none!important;
}

.checkout .woocommerce-error {
    margin-top:0px!important;
    margin-bottom:30px!important;
}

.blog-section {
    .row.blog:not(:first-child){
        margin-top:30px;
    }
}

.row.blog {
    a {
        color:$white;
        display:block;
    }
    a:hover {
        opacity:1;
        .blog-thumb {
            opacity:1;
            &:after {
                opacity:1;
            }
        }
        .logo-icon, .btn-line {
            opacity:1;
        }
    }
    .blog-thumb:first-child {
        padding-right:15px;
    }
    .blog-thumb:nth-child(2){
        padding-left:15px;
        padding-right:15px;
    }
    .blog-thumb:last-child{
        padding-left:15px;
    }
}

.blog-thumb {
    position: relative;
    @include bgCenter;
    background-repeat:no-repeat!important;
    height:450px;
    border-radius:5px;
    box-shadow:0px 5px 10px rgba(0,0,0,0.2); 
    z-index:0;
    overflow:hidden;
    .inner {
        position: absolute;
        bottom:-70px;
        left:0px;
        color:$white;
        padding:10px 20px;
        margin:10px;
        @include transition(0.4s);
        .logo-icon {
            width:50px;
            height: 50px;
            float:right;
            opacity:0;
            @include transition(0.3s);
        }
        .btn-line {
            opacity:0;
            @include transition(0.3s);            
        }
    }
    &:after {
        content:'';
        position: absolute;
        top:0px;
        left: 0px;
        width:100%;
        height: 100%;
        background-color:rgba(0,0,0,0.5);
        opacity:0;
        @include transition(0.5s);
        z-index:-1;
    }
}

.blog-thumb:hover {
    .inner {
        bottom:0px;
    }
}

.sidebar {
    ul {
        @include plainList;
        margin-top:10px;
        li {
            display: block;
            clear: both;
            padding:20px 0px;
            span {
                position: relative;
                top:-8px;
            }
        }
        li img {
            margin-right:10px;
            height:35px;
            width:35px;
            display:block;
            float:left;
        }
        li a {
            display: block;
            float:left;
            color:$black;
            font-size:16px;
            span {
                color:$blue;
                font-size:10px;
            }
        }
    }
}

.mb-img {
    height:250px;
    width:250px;
    border-radius:50%;
}

.about-img {
    width:75%;
    height:auto;
    float:right;
    border-radius:50%;
}

.profile-area {

    .sm {
        @include plainList;
        text-align:left;
        li {
            display:inline-block;
            a {
                display:inline-block;
                margin-left:10px;
                text-align:center;
            }
            .fb {
                width:50px;
                height:50px;
                background-color:#3B5998;
                border-radius:50%;                
            }
            .tw {
                width:50px;
                height:50px;
                background-color:#55ACEE;
                border-radius:50%;
            }
            i {
                font-size:30px;
                color:$white;
                line-height:50px;                
            }
        }
    }
}

.grey {
    color:$grey;
}

.single-post {
    .blog-img {
        width:100%;
        height:auto;
        border-radius:10px;
        margin-bottom:30px;
    }
}

.hero.mb {
    .mb-img {
        width:300px;
        height:300px;
        border-radius:50%;
        float:right;
    }
    .row {
        padding-top:40px;
        padding-bottom:20px;
        .hero-h1 {
            margin-top:30px;
        }
        .hero-h3 {
            float:right;
            margin-left:60px;
        }
    }
}

.gallery-section {
    .gallery-row:not(:first-child){
        margin-top:40px;
    }
}

.row.gallery-row {
    margin-top:40px;
    a {
        color:$white;
    }
    .inner {
        @include bgCenter;
        border-radius:10px;
        position:relative;
        p {
            margin-bottom:0px;
            i {
                text-align:center;
                height:40px;
                width:40px;
                line-height:40px;
                position:relative;
                margin-bottom:15px;
                &:after {
                    content:'';
                    position:absolute;
                    width:100%;
                    height:100%;
                    top:0px;
                    left:-2px;
                    border:2px solid $white;
                    border-radius:50%;
                }
            }
            display:block;
            position:absolute;
            bottom:0px;
            padding:20px;
        }
    }
}

.grey-bg {
    background-color:#F9F9F9;
}

section.references {
    overflow:hidden;
}

.ref-slider {
    .reference {
        img {
            width:50px;
            height:50px;
            border-radius:50%;
            display:inline-block;
        }
        .detail {
            display:inline-block;
            position:relative;
            top:10px;
            margin-left:15px;
            .name {
                position:relative;
                top:20px;
                color:$grey;
                font-size:18px;
            }
        }
    }
}

.ref-slider .slick-next, .ref-slider .slick-prev {
    display:none!important;
}

.ref-slider .slick-dots {
    @include plainList;
    margin:0 auto;
    text-align:center;
    margin-top:80px;
    li {
        display:inline-block!important;
        color:transparent!important;
        margin:0px 5px;
        button {
            background-color:$pink!important;
            border:none!important;
            width:20px;
            height: 20px;
            border-radius:50%;            
        }
    }
    li {
        button:hover {
            opacity:0.5;
        }
    }
}

.ref-slider {
    width:130vw;
    left:-15vw;
    .slick-slide {
        @include transition(0.4s);
        transform:scale(0.5);
        opacity:0.5;
    }
    .slick-current {
        transform:scale(1);
        opacity:1;
    }
}

.africa-grey, section.about-section.africa-grey {
    background-size:100vw!important;
    background-repeat:no-repeat!important;
    background-position:center bottom!important;    
    background:url('/img/africa_grey.jpg');
}

.gallery-row.gallery {
    .inner {
        position:relative;
        height:250px;
    }
    img {
        position:absolute;
        top:20px;
        right:20px;
        width:25px;
        height: 25px;
    }
}

.gallery-thumb {
    cursor:pointer;
    @include transition(0.4s); 
    p {
        color:$white;
    }
    &:hover {
        opacity:0.9;
    }
}

.gallery-row.gallery .inner a {
    display:block;
    height:100%;
    width:100%;
}

.blog-search {
    label {
        background-color:$pink;
        color:$white;
        display:inline-block;
        padding:5px 10px;
        margin-bottom:10px;
        font-size:20px;        
    }
    input[type=search] {
        display: block;
        clear: both;
        border:none;
        background-color:rgba(255,255,255,0.6);
        width:100%;
        height:35px;
        padding-left:15px;
        margin-bottom:10px;
        font-family:$bodyFont;
    }
    input[type=submit]{
        width:auto;
        padding:10px;
        font-size:15px!important;
    }
}

.submenu-parent {
    position:relative;
    .parent {
        height:37px;
    }
}

.submenu {
    position:absolute;
    background-color:$black;
    top:37px;
    width:250px;
    left:-1px;
    opacity:1;
    z-index:-1;
    display:none;
    @include transition(0.4s);
    li {
        margin:0px 0px!important;
        padding:10px;
        background-color:$blue;
        width:100%;
        position:relative;
        a {
            color:$blue!important;
        }
    }
    li:hover {
        a {
            opacity:1!important;
        }
    }
}

.submenu-parent:hover .submenu {
    opacity:1;
    display:block;
    z-index:50;
}

.submenu {
    height:37px;
}

.submenu-parent a.parent:hover ~ .submenu {
    opacity:1;
    z-index:50;
}

.single-product-section .hero.header .with-avatar {
    margin-top:30px;
}

.single-product-section {
    .sku_wrapper {
        display:none;
    }
}

.variations {
    td {
        display:block;
        float:left;
        clear:both;
    }
    .value {
        width:100%;

    }
    .select2-container--default {
        width:100%;
    }
    .reset_variations {
        display:none!important;
    }
}

.related {
    padding-bottom:60px;
    background-color:#F9F9F9!important;
}

.store-select {
    .flag {
        width:150px;
        height:auto;
    }
    .btn {
        display:table;
        clear:both;
        margin:0 auto;
        margin-top:40px;
    }
}

.med-section {
  padding: 40px 0px;
}

.med-section h3.new-title {
  margin-bottom: 0px !important;
}

.gallery-section .gallery-row:not(:first-child) {
  margin-top: 20px;
}

.med-section .row.gallery-row {
  margin-top: 20px;
}

.single-project .newimg-bg {
  margin-bottom: 40px;
}

.no-hover:hover {
  cursor: default !important;
  opacity: 1 !important;
}

.missions .btn-sml.orange {
  min-width: 115px;
}

.hero-slide .slick-next {
  display: none !important;
}

.slick-dots {
  margin: 0px;
  padding: 0px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  position: relative;
}

.slick-dots {
  text-align: center;
  display: block;
  position: absolute;
  bottom: 40px;
  width: 100%;
}

.slick-dots li {
  display: inline-block !important;
}

.slick-dots li button:before {
 position: absolute;
 width: 10px;
 height: 10px;
 background: #fff !important;
 content: '';
 left: 0px;
 right: 0px;
 border-radius: 50%;
 opacity: 0.8;
 transition: .3s; 
}



.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

@media screen and (min-width:2200px){
}

@media screen and (max-width:1999px){
    .navbar-nav {
        li {
            margin:0px 0px!important;
            a {
                font-size:20px!important;
                padding:7px;
            }
        }
    }
}

@media screen and (max-width:1500px){
}

@media screen and (max-width:1300px){
}

@media screen and (max-width:1200px){
    .hero.header .offset-text.with-avatar .avatar {
        margin-top:-60px!important;
    }
}

@media screen and (max-width:992px){
    .navbar-nav {
        li {
            margin:0px 0px!important;
            a {
                font-size:15px!important;
                padding-left:10px;
                padding-right:10px;
            }
        }
    }
    .cta-section {
      .info-box:not(:first-child){
          margin-top:40px;
      }
    }
    section.section-mission {
        h5 {
            font-size:1.5em!important;
        }
    }
    .mission-box {
      padding:0px 15px!important;
      .video-col {
          border-top-right-radius:10px;
          border-top-left-radius:10px;
      }
      .info-col {
          padding-top:40px;
          padding-bottom:15px;
          border-bottom-right-radius:10px;
          border-bottom-left-radius:10px;
          .inner {
              margin-top:0px;
              border-top-right-radius:0px;
              border-bottom-right-radius:10px;
              border-bottom-left-radius:10px;
          }
      }
    }
    .mission-list {
      .mission-thumb:not(:first-child){
          margin-top:20px;
      }
    }
    .fundraiser-cta {
      text-align:center;
      padding:40px 0px;
      .fundraiser-title {
          display:block;
      }
      a.btn {
          margin-top:20px;
          display:inline-block;
          clear:both;
          float:none;
      }
    }
    .hero.header {
        .offset-text {
            padding-left:0px!important;
        }
    }
    .about-section {
        .inner {
            margin-top:20px!important;
        }
    }
    .founder {
        margin-bottom:0px;
        margin-top:40px;
        .member:not(:first-child){
            margin-top:40px;
        }
    }
    .double-img-section {
        .offset-up, .offset-down {
            margin-top:20px;
        }
        .pull-right {
            clear:both!important;
            float:none!important;
        }
    }
    .contact-form.pull-right {
        float:none!important;
    }
    .contact-section {
        .addresses {
            clear:both;
            margin-top:60px;
        }
    }
    .hero.header .offset-text.with-avatar .avatar {
        margin-top:50px!important;
    }
    .hero.header .offset-text.with-avatar p {
        width:100%;
    }
    .hero.header.single-prod {
        text-align:center;
        .avatar {
            margin-top:40px!important;
        }
    }
  .hero.mb {
    text-align:center;
    .hero-h1, .hero-h3 {
        float:none!important;
        margin:none!important;
        margin-left:0px!important;
    }
  }
  .about-section .about-img {
    display:none;
  }
  .hero.mb .mb-img {
    height:75px;
    width:75px;
    margin:0 auto;
    float:none;
    margin-top:15px;
  }
  .profile-area {
    text-align:center;
    .col-md-6:not(:first-child){
        margin-top:40px;
        img {
            margin-bottom:5px;
        }
    }
    .sm {
        text-align:center;
    }
  }
  .ref-slider {
    width:100vw;
        left:0vw;
        .slick-slide {
            @include transition(0.4s);
            transform:scale(0.5);
            opacity:0.5;
        }
        .slick-current {
            transform:scale(1);
            opacity:1;
        }
   }
   .mobile-nav {
    display:block;
        .hamburger {
            top:30px;
            right:15px;
        }
    }
    .mobile-nav-wrap {
        .hamburger {
        position: absolute;
        top: -70px;
        right: 15px;
        }
    }
    .navbar-nav {
        display:none;
    }
    .navbar .container .navbar-brand img {
        margin-top:10px;
    }
}

@media screen and (max-width: 767px) {
  h1 {
      font-size:1.6em!important;
  }
  h2 {
      font-size:1.4em!important;
  }
  h3 {
      font-size:1.3em!important;
  }
  h4 {
      font-size:1.2em!important;
  }
  h5 {
      font-size:1em!important;
  }
  p {
      font-size:1em!important;
  }
  .btn {
      font-size:18px;
  }
  .content-section, .single-product .cta-section {
      padding:40px 0px;
  }
  .hero {
      padding:50px 0px 180px 0px;
      .hero-content {
          margin-top:0px;
      }
  }
  .info-box .inner {
    margin-top:15px;
      margin-left:0px;
      margin-right:0px;
  }
  .info-box .inner.single-product {
    height:300px;
    margin-top:15px;
    margin-bottom:5px;
  }
  footer {
      .footer-col:not(:first-child){
          margin-top:40px;
      }
      .footer-col {
          text-align:center;
      }
      .row.menu {
          padding-bottom:60px;
      }
  }
  
  .about-section, .double-img-section {
      .inner {
          max-height:250px;
      }
  }
  .mission-box {
      .info-col {
          .brush.right {
              line-height:35px;
          }
          .brush:after {
              display:none;
          }
      }
      .video-col {
          max-height:250px;
      }
  }
  table.missions {
    td {
        font-size:18px;
        .btn {
            font-size:18px;
        }
    }
  }
  .cta-section {
    .row.title {
        padding-left:0px;
    }
  }
  img.shop-item {
    max-width:300px;
    margin-bottom:20px;
  }
    .woocommerce-breadcrumb {
       left:0px!important;
    }

  .product.type-product {
    padding:0px 15px;

    div.images img {
        max-height:320px!important;
        width:auto!important;
    }
  }
  .single-product-section {
      padding-bottom:40px;
  }
  .content-section.archive .info-box {
      .inner {
          width:100%;
      }
      .row {
          margin-top:15px;
      }
      width:100%;
      padding-left:15px;
      padding-right:15px;
  }
  .cta-section.archive .info-box:not(:first-child){
      margin-top:15px;
  }
  .cta-section.no-offset.archive .row:not(:first-child){
      margin-top:15px;
  }
  .woocommerce-breadcrumb {
    margin-bottom:0px!important;
  }
  .woocommerce div.product div.images img {
      margin-top:40px;
  }
  div.summary.entry-summary {
      margin-bottom:0px!important;
  }
  .cta-section.no-offset.sponsor {
      .row {
          margin-top:0px;
      }
      .info-box {
          margin-top:15px;
      }
  }
  .single-product .cta-section .row.title {
      margin-bottom:10px;
  }
  .woocommerce-error, .woocommerce-message {
      margin-left:0px;
      margin-bottom:0px;
      margin-top:40px;
  }
  .woocommerce-info {
      margin-left:15px;
      margin-right:15px;
  }
  .woocommerce form {
      box-shadow:none!important;
      border:none!important;
  }
  .cta-section.no-offset.sponsor {
      padding-top:0px!important;
  }
  .single-product .cta-section.no-offset.sponsor {
      padding-top:40px!important;
      .info-box .inner.single-product {
          width:100%;
      }
  }
  .single-product-section .product {
      padding-left:0px;
      padding-right:0px;
  }
  .gallery-section .gallery-row:not(:first-child) {
      margin-top:0px;
  }
  .about-section.africa-grey {
    .gallery-row {
        margin-top:0px;
    }
  }
  .row.content.title {
      margin-bottom:15px;
  }
  .sidebar {
      display:none;
  }
  .blog-search {
      display:none;
  }
  .page-template-page-blog {
      .hero.header .hero-content {
          text-align:center;
      }
  }
}

@media screen and (max-width:641px){
}

@media screen and (max-width:600px){
  table.missions {
    td {
        font-size:14px;
        .btn {
            font-size:14px;
        }
    }
  }
}

@media screen and (max-width:500px){
    .hero.header .offset-text.with-avatar .avatar {
        margin-top:5px!important;
        margin-bottom:10px;
    }
    .hero.header {
        padding:50px 0px;
    }
    .hero.header .offset-text {
        p {
            margin-left:0px!important;
            display:block!important;
            clear:both!important;
            width:100%!important;
        }
    }
    .hero.single-prod {
        text-align:center;
    }
    .btn.btn-pink, .btn.btn-clear {
        font-size:13px!important;
    }
    .store-item {
        .slick-list {
            height:300px;
        }
        .slide {
            height:300px;
            .inner {
                height:300px;
            }
        }
    }
    .product-carousel {
        .slick-dots {
            li {
                button {
                    height:27px;
                    width:27px;
                }
            }
        }
    }
    .hero.header .offset-text.with-avatar .avatar {
        margin-top:60px!important;
    }
    .blog-section .row .blog-thumb {
        margin-bottom:15px;
    }
    .blog-section .row.blog:not(:first-child) {
        margin-top:0px;
    }
}

@media screen and (max-width:450px){
    .product.type-product div.images img {
        min-width:100%!important;
        max-height:450px!important;
        height:auto!important;
    }
}

@media screen and (max-width:400px){
  table.missions {
    td {
        font-size:11px;
        .btn {
            font-size:11px;
        }
    }
  }
  img.shop-item {
    max-width:100%;
    margin-bottom:20px;
  }
  .store-item {
      h3 {
      }
  }
}


.navbar-inverse, .navbar.fixed-navbar {
  background: #fff !important;
  padding: 15px 0px !important;
  border-radius: 0px !important;
}

#navbar .navbar-nav {
  margin-top: 0px;
}

.navbar ul li a {
  color: #333 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  letter-spacing: 1.5px;
}

/*
#navbar .navbar-nav li.donate {
  display: none !important;
}
*/

.donate-count {
  color: #fff !important;
  background: #333 !important;
}

.navbar-inverse.fixed-navbar:after, .navbar-inverse.fixed-navbar:before {
  display: none !important;
}

.navbar .container .navbar-brand img {
  height: 35px;
  margin-top: 3px;
}

.hero-content {
  text-align: center;
}

h1, h2, h3, h4, h5, label {
  font-weight: normal !important;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.hero .hero-h1 {
  font-size: 42px;
}

.hero p {
  font-size: 20px;
  line-height: 1.6em;
  margin-bottom: 25px;
}

.btn.btn-clear {
  border-radius: 0px !important;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
}

.cta-section {
  margin-top: 0px;
}

.img-block {
  display: block;
  width: 100%;
  height: 500px;
  background-size: cover !important;
  background-position: center right !important;
  position: relative;
}

.img-block .p-content {
  position: absolute;
  bottom: 25px;
  left: 15px;
}

.img-block p {
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 22px;
  margin-bottom: 10px;
}

.sponsor-carousel:after {
  display: none !important;
}

.sponsor-carousel .name {
  font-size: 20px;
}

.new-title {
  margin-bottom: 25px;
}

.footer-cta {
  padding: 40px 0px 80px;
}

.img-block.sm-img {
  height: 240px;
}

.newimg-bg {
  background-size: cover !important;
  background-position: center center !important;
  display: block;
  box-shadow: 2px 5px 10px rgba(0,0,0,0.1);
  height: 220px;
}

.div-line {
  width: 100%;
  height: 1px;
  background: #d9d9d9 !important;
  margin: 25px 0px 40px;
}

.about-new h3.title-underline {
  position: relative;
  display: inline-block;
}

.about-new h3.title-underline:before {
  position: absolute;
  left: 0px;
  height: 2px;
  background: #333;
  width: 75%;
  content: '';
  bottom: -5px;
}

.circle-bg {
  width: 120px;
  height: 120px;
  background-size: cover !important;
  background-position: center center !important;
  display: block;
  margin: auto;
  border-radius: 50%;
  margin-top: 25px;
}

.half-section .col-md-6 {
  padding: 0px;
}

.half-bg {
  display: block;
  background-size: cover !important;
  height: 520px;
  background-position: center center !important;
}

.half-content {
  height: 520px;
  position: relative;
}

.half-center {
  position: relative;
  transform: translate(0,-50%);
  -webkit-transform: translate(0,-50%);
  top: 50%;
  max-width: 80%;
  margin: auto;
}

.fundraiser-cta {
  background: #ebebeb !important;
  color: #333 !important;
}

.fundraiser-cta .fundraiser-title {
  font-size: 30px;
  color: #333;
}

.fundraiser-cta .btn {
  border-color: #333 !important;
  color: #333 !important;
}

.fundraiser-cta .fundraiser-title {
  position: relative;
  top: 9px;
}

.submenu {

  background: #fff !important;
}

.submenu li {
  background: #fff !important;
}

.new-title.title-underline {
  position: relative;
  display: inline-block;
}

.new-title.title-underline:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: -5px;
  height: 2px;
  width: 70%;
  background: #333;
}

.misson-next .new-title {
  color: #7d7d7d !important;
}

.misson-next .new-title.title-underline:before {
  background: #7d7d7d !important;
}

.misson-next p {
  font-size: 20px;
}

.misson-next p span {
  font-size: 20px;
  margin-right: 5px;
  color: #7d7d7d !important;
}

.misson-next .icon-calendar:before, .misson-next .icon-pin:before {
  color: #7d7d7d !important;
}

.mission-dates {
  background-size: cover !important;
}

.mission-dates h3.new-title {
  color: #fff !important;
}

.mission-dates h3.new-title:before {
  background: #fff !important;
}

table.missions td {
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 18px !important;
}

.row.dates {
  margin-top: 40px;
}

table.missions .desc {
  background: #fff !important;
  color: #333 !important;
}

.missions .btn-sml.orange {
  background: #fff !important;
  border-radius: 0px !important;
  color: #333 !important;
}

.missions .icon-pin:before, .missions .icon-calendar:before {
  color: #fff !important;
}

.sing-proj-head p {
  margin-top: 60px;
  position: relative;
  top: 10px;
}

.sing-proj-head .icon-calendar:before {
  color: #fff !important;
}

.big-quote {
  position: relative;
}

.big-quote img {
  position: absolute;
  max-width: 120px;
  top: -40px;
  left: -50px;
  z-index: 1;
}

.quote-inf {
  position: relative;
  z-index: 2;
}

.quote-sect {
  background: #ebebeb !important;
  padding: 100px 0px 20px;
}

.quote-inf h4 {
  text-transform: none !important;
  letter-spacing: 0px;
  font-size: 36px;
}

.store-select .btn {
  border-radius: 0px !important;
  background: transparent !important;
  border: 2px solid #333 !important;
  color: #333 !important;
}

.info-box .inner.single-product {
  box-shadow: none !important;
}

.info-box .price {
  color: #333 !important;
  margin-bottom: 15px;
}

.info-box .line-btn {
  border: 2px solid #000;
  font-size: 14px;
  padding: 10px 25px;
  display: inline-block;
  margin-top: 5px;
}

.info-box h3 {
  font-size: 15px !important;
  margin-top: 10px;
}

.woocommerce div.product .product_title, .woocommerce div.product .product_title {
  text-transform: uppercase !important;
  font-weight: normal !important;
  letter-spacing: 1.5px !important;
}

.woocommerce .cta-section.no-offset.sponsor.related {
  display: none !important;
}

.product.type-product .product_title:after {
  display: none !important;
}

.woocommerce div.product form.cart .button {
  background: transparent !important;
  border-radius: 0px !important;
  border: 2px solid #333 !important;
  color: #333 !important;
}

.row.gallery-row .inner {
  height: 250px;
  border-radius: 0px !important;
}

.row.gallery-row .inner p {
  padding-bottom: 0px;
}

.media-info {
  text-align: center;
}

.row.gallery-row .media-info p {
  text-transform: uppercase !important;
  letter-spacing: 1.5px !important;
  color: #333 !important;
  font-size: 20px;
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.row.gallery-row .media-info p.sm-title {
  color: #a8a8a8 !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  font-size: 14px !important;
  margin-top: 10px;
}

.gallery-thumb p {
  padding-bottom: 10px !important;
}

.africa-grey, section.about-section.africa-grey {
  background: #fff !important;
}


.contact-section .title-brush .brush:after {
  display: none !important;
}

.contact-section span.country {
  font-weight: normal !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn.btn-pink, form input[type=submit] {
  background: transparent !important;
  border: 2px solid #333 !important;
  color: #333 !important;
  border-radius: 0px !important;
}

.woocommerce-error {
  border: 2px solid #333;
  background: transparent !important;
  color: #333 !important;
  border-radius: 0px !important;
}

.place-order input[type=submit], .page-template-page-cart .checkout-button {
  background: transparent !important;
  border: 2px solid #333 !important;
  color: #333 !important;
  border-radius: 0px !important;
}

.coupon .button {
  border: none !important;
}

.obo-social a {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
  color: #333 !important;
  margin-bottom: 10px;
}

.hero.header {
  padding: 160px 0px 160px 0px;
  background-position: center bottom !important;
}

.content-section.about-new {
  padding-bottom: 0px !important;
}
