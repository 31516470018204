.product-cart input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background: #f5f5f5 !important;
    display: inline-block !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    height: 42px;
}

.content-section.page-section.single-product {
    p:last-child {
        margin-bottom:0px!important;
    }
    .woocommerce-product-details__short-description {
        h4 {
            margin-bottom:40px;
        }
    }
    .prod-small-image {
        margin-left:-10px;
        margin-right:-10px;
        .col-md-4 {
            padding:0px 10px;
            height:142px;
            margin-top:20px;
            img {
                height:142px;
            }
        }
        .col-md-4:last-child {
            margin-bottom:40px;
        }   
    }
}

.main-product-img img {
    max-width:100%!important;
    height:auto!important;
}

.product-info {
    form.product-cart {
        margin:15px 0px;
        padding-bottom:0px;
        border:none;
        box-shadow:none;
        button {
            margin-top:15px;
        }
    }
    .price {
        margin-top:10px;
        margin-bottom:40px;
    }
    .price del {
    background-color: red;
    padding: 6px;
    border-radius: 5px;
    margin-right: 15px;
    }
    .price ins {
    background-color: $blue;
    padding: 6px;
    border-radius: 5px;
    }
    input[type=number]{
        width:100px!important;
    }
}

.img-col {
    .onsale {
    position: relative;
    background-color: pink;
    color: #000;
    left: -30px;
    top: 29px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    }
}

.shop_table {
    background-color:#ecebeb;
    width:100%;
    border-radius:5px;
    overflow:hidden;
}

.shop_table thead {
  background-color:$black;
  color:$white;
  padding-top:25px;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 15px;
  text-align: center;
  th {
      padding:10px 0px;
  }
}

.shop_table tr td, .shop_table tr th {
  padding: 12px 15px 12px 15px;
}

.cart_item td.product-remove {
    padding-left:15px!important;
    a {
        font-size:35px;
    }
}

.woocommerce-order-details__title, .woocommerce-customer-details h2, .woocommerce-column__title {
    padding-left:0px!important;
}

abbr[title] {
    text-decoration:none!important;
}

.shop_table tr td.actions {
  text-align: right;
}

.shop_table .input-text {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background: #f5f5f5 !important;
  display: inline-block !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  height: 45px;
  width: auto !important;
}

td.product-name a {
  color: $black !important;
}

.cart_item {
  border-bottom: 1px solid $black !important;
}

input[name=coupon_code] {
    height:50px;
    font-family:$bodyFont!important;
}
input[name=apply_coupon]{
    padding:15.5px!important;
    position:relative!important;
    top:0px!important;
}
.cart_item td {
  padding: 25px 15px 25px 0px !important;
}

.shop_table {
  margin-bottom: 20px;
}

.checkout-button, .coupon .button, .actions .button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    border: 1px solid #4cbc49;
    outline: none !important;
    box-shadow: none !important;
    letter-spacing: 1px;
    color: #000;
    background: #4cbc49;
    color: #fff !important;
    padding: 15px 25px;
    font-size: 16px;
}

.actions .button {
  background: $black !important;
  color: #fff !important;
  padding: 10px 25px;
  border-color: $black !important;
}

.cart-empty {
    padding-left:15px;
}

.woocommerce-error {
  padding: 20px !important;
  list-style-type: none !important;
  &:before {
      display:none;
  }
}

.coupon .button {
  padding: 9px 25px;
  position: relative;
  top: -2px;
  background: #fff !important;
  color: #000 !important;
  border-color: #d8d8d8 !important;
}

.coupon {
  margin-bottom: 30px;
}

.wc-proceed-to-checkout {
  text-align: right;
}

.coupon label {
  margin-right: 15px;
}

.shop_table .coupon .input-text {
  margin-right: 15px;
}

.cart-section .woocommerce-message {
    left:15px;
    a {
        color:$white;
        padding-bottom:2px;
        border-bottom:2px solid $white;
    }
}

.cart-wrap .woocommerce-message {
  background: #4cbc49 !important;
  margin-bottom: 25px;
  &:before {
      display:none;
  }
}

a.remove {
    line-height:30px;
}

.cart-empty, .return-to-shop {
    padding:15px;
    margin-bottom:0px!important;
}

.woocommerce-error {
    padding:13.5px!important;
    margin-right: 30px;
    margin-left: 0px;
    margin-top:40px;
    margin-bottom:-40px;
    position: relative;
    display: inline-block !important;
    line-height: 30px;
    border-top: 0px;
    border-color: transparent;
    background-color: #F274AC;
    color: #fff;
    border-radius: 7.5px;
    font-weight: bold;
    font-size: 15px;
    position: relative;
    top: -2px;
    a.button {
        margin-left:15px;
    }
    &:before {
        display:none;
    }
}

.single-product-section {
    .woocommerce-message {
        margin-bottom:-40px;
    }
}

.woocommerce-message {
    a.button {
        margin-left:15px;
    }
}

.cart-wrap h2 {
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: 25px !important;
}

#order_comments_field {
  display: none;
}

.woocommerce-billing-fields h3 {
  background: $black;
  color: #fff;
  margin: 0px;
  padding: 15px 15px 10px 15px;
  margin-top: 25px !important;
  border-top-right-radius:5px;
  border-top-left-radius:5px;
}

.woocommerce-info a {
  color: $black !important;
}

.checkout_coupon .input-text, .woocommerce-billing-fields .input-text  {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background: $white !important;
  display: inline-block !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  height: 45px;
  width: auto !important;
  font-family:$bodyFont;
}

.woocommerce-billing-fields .input-text {
  width: 100% !important;
}

.woocommerce-billing-fields__field-wrapper:before, .woocommerce-billing-fields__field-wrapper:after {
  display: table;
  content: " ";
  clear: both;
}

.woocommerce-billing-fields label {
  font-weight: 400 !important;
  display: block !important;
}

.form-row {
  float: left!important;
  width: 50% !important;
  padding: 0px 15px;
  height: auto!important;
}

abbr[title] {
  border: none !important;
}

.checkout_coupon .input-text {
  background: #fff !important;
  margin-top: 15px !important;
}

.woocommerce-billing-fields__field-wrapper {
  background: #ecebeb !important;
  padding: 25px 5px;
}

.woocommerce-additional-fields {
    h3 {
        margin-top:40px;
    }
    label.checkbox {
        margin-top:15px;
    }
}

.woocommerce form .form-row label.checkbox {
    line-height:60px!important;
}

#gift-aid-heading {
    font-size:20px;
    margin-top:30px;
}

.woocommerce .col2-set .col-2 {
    float:left!important;
    width:100%;
    padding-left:15px;
}

.checkout_coupon .button {
  border: 1px solid #d8d8d8;
  outline: none !important;
  box-shadow: none !important;
  letter-spacing: 1px;
  color: #000;
  background: #fff;
  padding: 8px 25px;
  font-size: 16px;
}

.select2-container--default .select2-selection--single {
  height: 45px;
  background: #f5f5f5 !important;
  border: none !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 45px;
  line-height: 45px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 43px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $black !important;
  color: #fff !important;
}

.notification-section {
    .woocommerce-message {
        position:relative;
        left:-15px;
        margin-bottom:20px;
    }
}

#billing_address_2_field, #shipping_address_2_field {
  margin-top: 30px;
}

.cart_totals, #order_review {
    table {
        tr {
            th, td, .cart_item td  {
                padding:25px!important;
            }
        }
    }
}

.wc_payment_methods {
    @include plainList;
    li:not(:last-child) {
        margin-bottom:20px;
        padding-bottom:20px;
        border-bottom:1px solid $black;
    }
}

#order_review_heading {
    margin-top:40px;
}

.place-order {
    margin-top:40px;
}

.woocommerce-message {
    padding:15px 20px!important;
    margin-right:30px;
    margin-top:40px!important;
    position:relative;
    left:0apx;
    line-height:30px;
    border-top:0px;
    border-color:transparent;
    background-color:$pink;
    color:$white;
    border-radius:7.5px;
    font-weight:bold;
    font-size:15px;
    display:inline-block;
    .button {
        margin-left:25px;
    }
    &:before {
        display:none;
    }
}

body.woocommerce-account {
    .col-lg-3 {
        display:none!important;
    }
}

.woocommerce-MyAccount-content {
    .woocommerce-address-fields {
        margin-top:20px;
    }
    h3, h2 {
        padding:15px;
    }
    textarea {
        height:250px;
        resize:none;
        padding:10px;
    }
    input[type=submit]{
        position:relative;
        top:30px;
        left:15px;
    }
}

.woo-link {
    padding-bottom:1px;
    border-bottom:1px solid pink;
}

.u-columns.woocommerce-Addresses {
    h3 {
        padding-left:0px;
    }
}

.woocommerce-ResetPassword.lost_reset_password {
    input[type=submit]{
        position:relative;
        top:29px;
    }
}

.woocommerce-EditAccountForm.edit-account {
    width:100%;
}

fieldset {
    width:100%;
    padding:15px;
    margin-left:0px;
    margin-top:20px;
    clear:both;
    .woocommerce-form-row {
        padding-left:0px;
    }
}

.woocommerce-EditAccountForm.edit-account {
    padding:20px;
    padding-bottom:60px;
}

.woocommerce-MyAccount-navigation {
    ul {
        @include plainList;
        margin-bottom:50px;
        li {
            display:inline-block;
            background-color:pink;
            padding:15px 20px;
            border-radius:10px;
            @include transition(0.3s);
        }
        li:hover {
            opacity:0.8;
        }
        li:not(:first-child){
            margin-left:20px;
        }
    }
}

.woocommerce {
    .edit {
        font-size:16px;
        color:$black!important;
        padding:5px 10px;
        display:inline-block;
        border-radius:5px;
        background-color:pink;
        margin:5px 0px 10px 0px;
    }
    input[type=text], input[type=number], input[type=password], input[type=tel], input[type=email], textarea {
        border:none;
        background-color:#ececec;
        color:$black;
        border-radius:5px;
        width:100%;
        height:45px;
        padding:0px 10px;
    }
    label, input {
        display:block;
        clear:both;
    }
    form {
        border:1px solid #ececec;
        border-radius:5px;
        box-shadow:0px 0px 15px rgba(0,0,0,0.05);
        padding:20px;
        padding-bottom:100px;
    }
    input[type=checkbox]{
        display:inline-block;
        margin-top:20px;
    }
    .lost_password {
        padding-bottom:2px;
        border-bottom:1px solid pink;
        display:inline-block;
        position:relative;
        left:15px;
    }
}

.woocommerce-table--order-details {
    th, td {
        padding:10px;
    }
}

.woocommerce-order {
    .woocommerce-notice {
        margin-bottom:30px;
    }
}

.woocommerce-order-details {
    .woocommerce-order-details__title {
        margin-top:50px;
    }
    .shop_table tr td {
        padding:15px;
    }
    .shop_table th {
        padding:15px;
    }
    .woocommerce-column__title {
        margin-bottom:5px;
    }
    address {
        background-color:#ecebeb;
        border-radius:7.5px;
        padding:15px;
    }
}

.woocommerce-order-overview {
    list-style-type:none;
    li {
        line-height:40px;
        font-size:20px;
        position:relative;
        border-right:none!important;
        &:after {
            content:'\f10c';
            font-family:'FontAwesome';
            font-size:16px;
            color:pink;
            position:absolute;
            top:1px;
            left:-30px;
            display:block;
            float:left;
            clear:both;
        }
    }
}

.woocommerce-shipping-calculator {
    box-shadow:none!important;
    margin-top:20px!important;
    padding-left:0px!important;
    padding-bottom:20px!important;
}

tr.shipping {
    vertical-align:top;
}

#shipping_method {
    @include plainList;
    li {
        input,label {
            display:inline-block;
        }
        label {
            margin-left:5px;
        }
    }
}

.woocommerce-account-fields {
    margin-top:20px;
}

#ship-to-different-address {
    font-size:18px!important;
    label {
        margin-bottom:40px;
        margin-left:30px;
        span {
            display:inline-block;
            position:relative;
            top:13px;
            left:5px;
        }
    }
}

#order_review_heading {
    display:none;
}

#order_review {
    position:relative;
    top:30px;
    display:block;
    clear:both;
}

.create-account {
    label {
        margin-left:15px;
        display:inline-block;
        span {
            display:inline-block;
            position:relative;
            top:13px;
            left:5px;
        }
    }
}

.woocommerce-info {
    margin-bottom:25px;
    .showcoupon, .showlogin {
        border-bottom:1px solid pink;
        padding-bottom:1px;
    }
}

.woocommerce-checkout {
    padding-bottom:180px!important;
}

.woocommerce {
    a.about-paypal {
        margin-left:10px;
    }
    .wc_payment_method {
        img {
            margin-left:10px;
            margin-right:10px;
        }
        input, label {
            display:inline-block!important;
        }
        label {
            margin-left:10px;
        }
    }
}

.woocommerce-MyAccount-navigation-link:hover, .woocommerce-MyAccount-navigation-link:focus {
    color:$black!important;
}

.woocommerce-orders-table {
    .shop_table .woocommerce-orders-table__row td:first-child a {
        padding-left:15px!important;
    }
}

.content-section.best-sellers {
    padding-bottom:0px;
}

.page-template-page-cart {
    .woocommerce form {
        padding-bottom:0px;
    }
    .woocommerce-cart-form {
        box-shadow:none;
        border:none;
    }
    .woocommerce {
        border:1px solid #ececec;
        box-shadow:5px 5px 10px rgba(0,0,0,0.15);
    }
}

.place-order input[type=submit], .page-template-page-cart .checkout-button {
    display:inline-block!important;
    border-radius:7.5px!important;
    color:$white!important;
    background-color:$pink!important;
    border:2px solid $white!important;
    @include transition(0.3s);
    padding:15px 20px!important;
}

.cart_totals {
    width:100%!important;
    padding:20px;
}

form[name=checkout]{
    .col-1 {
        width:100%!important;
    }
}

.woocommerce-info {
    border-top-color:$blue!important;
    padding-left:20px!important;
    &:before {
        display:none;
    }
}

.woocommerce form .form-row {
    width:100%!important;
}

.woocommerce-billing-fields {
    h3 {
        margin-top:0px!important;
    }
}

.product.type-product {
    form {
        border:none;
        box-shadow:none;
        padding:0px;
        padding-bottom:0px;
    }
}

body.woocommerce-checkout {
    padding-bottom:0px!important
}

.single-product div.product {
    padding-top:60px;
}

.woocommerce-product-details__short-description {
    p {
        line-height:2em;
    }
}

@media screen and (max-width:767px){
    .woocommerce table.shop_table_responsive tr td, .woocommerce-page table.shop_table_responsive tr td {
        padding:15px!important;
    }
    .woocommerce .form {
        box-shadow:none;
        border:none;
    }
    .woocommerce .coupon {
        text-align:center;
        #coupon_code {
            font-family:$bodyFont!important;
            padding-left:15px;
        }
        input {
            display:inline-block!important;
            float:none!important;
            width:100%!important;
        }
    }
    .woocommerce .coupon input[type=submit]{
        margin-top:15px;
    }
}